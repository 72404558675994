import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3b2c88ca&scoped=true&"
import script from "./default.vue?vue&type=script&lang=jsx&"
export * from "./default.vue?vue&type=script&lang=jsx&"
import style0 from "./default.vue?vue&type=style&index=0&id=3b2c88ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2c88ca",
  null
  
)

export default component.exports